export const environment = {
  production: true,
  API_ENDPOINT: 'https://vrm.al-sadhan.com/v2/api/',
  // API_ENDPOINT: 'http://20.173.40.210:3001/v2/api/',
  firebaseConfig: {
    apiKey: 'AIzaSyCR-3WZBUiZuSSgdoH63x5WSUsGSSFBtjU',
    authDomain: 'vendor-5c444.firebaseapp.com',
    projectId: 'vendor-5c444',
    storageBucket: 'vendor-5c444.appspot.com',
    messagingSenderId: '1063137373466',
    appId: '1:1063137373466:web:30d81cb6394987422450a3',
    measurementId: 'G-03NWFZSKSS',
  },
};
