// English
export const locale = {
  lang: 'en',
  data: {
    DASHBOARD: {
      OPEN_ORDERS: 'Open Orders',
      GRN: 'GRN',
      OOS_ITEMS: 'OOS Items',
      STOCK_QTY: 'Stock Quantity',
      STOCK_VALUE: 'Stock Value',
      SLA: 'SLA',
      QUANTITY: 'Quantity',
      TOTAL_ORDERED_QTY: 'Total Ordered Quantity',
      TOTAL_RECEIVED_QTY: 'Total Received Quantity',
      VALUE: 'Value',
      TOTAL_ORDERED_VALUE: 'Total Ordered Value',
      TOTAL_RECEIVED_VALUE: 'Total Received Value',
      CONTRACTS: 'Contracts',
      APPROVED_SELL_OUT: 'Approved Sell-out',
      APPROVED_SELL_IN: 'Approved Sell-in',
      APPROVED_OTHER_REVENUE: 'Approved Other Revenue',
      PENDING_SELL_OUT: 'Pending Sell-out',
      PENDING_SELL_IN: 'Pending Sell-in',
      PENDING_OTHER_REVENUE: 'Pending Other Revenue',
      MONTHLY_ORDER_FLOW: 'Monthly Order Flow',
      MONTHLY_GRN_FLOW: 'Monthly GRN Flow',
    },
    COMMON: {
      VENDOR: 'Vendor',
      DIVISION: 'Division',
      DEPARTMENT: 'Department',
      SECTION: 'Section',
      BRAND: 'Brand',
      ORDER_DATE: 'Order Date',
      DELIVERY_DEADLINE: 'Delivery Deadline',
      SITE: 'Site',
      DOWNLOAD: 'Download',
      DOWNLOADING: 'Downloading',
      SEARCH: 'Search',
      CLEAR: 'Clear',
      MORE: 'More',
      LOADING_DATA: 'Loading Data...',
      NO_DATA_FOUND: 'No Data Found',
      SHOWING: 'Showing',
      TO: 'to',
      OF: 'of',
      ENTRIES: 'entries',
      DATE: 'Date',
      FROM_DATE: 'From Date',
      TO_DATE: 'To Date',
      BACK: 'Back',
      NEXT: 'Next',
      CANCEL: 'Cancel',
      SAVE: 'Save',
      PROMOTION_PERIOD: 'Promotion Period',
      HIDE: 'Hide',
      SHOW: 'Show',
      AMOUNT: 'Amount',
      WITHOUT_VAT: 'Without VAT',
      WITH_VAT: 'With VAT',
      RATIO: 'Ratio',
      CREATE: 'Create',
      UPDATE: 'Update',
      SV_DESCRIPTION: 'SV Description',
      REMARKS: 'Remarks',
      UPLOAD_USING_EXCEL: 'Upload using Excel',
      DOWNLOAD_EXAMPLE: 'Download Example',
      NUMBER_DETAIL_LINES: 'Number Detail Lines',
      NUMBER_OF_SECTION: 'Number of Sections',
      NUMBER_OF_SITES: 'Number of Sites',
    },
    PURCHASE_ORDER: {
      PURCHASE_ORDER_FILTER: 'Purchase Order Filter',
      SITE_NUMBER: 'Site Number',
      PO_NUMBER: 'PO Number',
      ITEM_COUNT: 'Item Count',
      AMOUNT: 'Amount',
      STATUS: 'Status',
      STATUS_IS_REQUIRED: 'Status is required',
    },
    GOODS_RECEIPT_NOTE: {
      GOODS_RECEIPT_NOTE_FILTER: 'Goods Receipt Note Filter',
      INVOICE_NUMBER: 'Invoice Number',
      RECEPTION_DATE: 'Reception Date',
      RECEPTION_NUMBER: 'Reception Number',
      DN_NUMBER: 'DN Number',
      VALIDATION_DATE: 'Validation Date',
    },
    INVENTORY_MANAGEMENT: {
      INVENTORY_MANAGEMENT_FILTER: 'Inventory Management Filter',
      BARCODE: 'Barcode',
      STOCKS: 'Stocks',
      ARTICLE: 'Article',
      SUPPLIER: 'Supplier',
      STOCK_QTY: 'Stock QTY',
      ON_ORDER_QTY: 'On Order Qty',
      NORMAL_QTY: 'Normal QTY',
      BLOCKED_QTY: 'Blocked QTY',
      STOCK_VALUE: 'Stock Value',
      TOTAL: 'Total',
      TOTAL_VALUE: 'Total Value',
      VIEW_TYPE: 'View Type',
      ACTIVE_STOCKS: 'Active Stocks',
      ITEM_STATUS: 'Item Status',
    },
    SUPPORT: {
      SUPPORT_FILTER: 'Support Filter',
      SELL_OUT_SUPPORT_FILTER: 'Sell Out Support Filter',
      SELL_IN_SUPPORT_FILTER: 'Sell In Support Filter',
      OTHER_REVENUE_SUPPORT_FILTER: 'Other Revenue Support Filter',
      PROMOTION_FROM: 'Promotion From',
      PROMOTION_TO: 'Promotion To',
      DUE_DATE: 'Due Date',
      CONTRACT_TYPE: 'Contract Type',
      VAT: 'VAT',
      ADD_ITEM: 'Add Item',
      ADD_SUPPORT_TYPE: 'Add Support Type',
      UPDATE_SUPPORT_TYPE: 'Update Support Type',
      ARTICLE_CODE: 'Article Code',
      VARIANT_CODE: 'Variant Code',
      AMOUNT_WITHOUT_VAT: 'Amount without VAT',
      AMOUNT_WITH_VAT: 'Amount with VAT',
      PROMO_SELLING_PRICE_WITH_VAT: 'Promo Selling Price with VAT',
      ACTION: 'Action',
      SITE_CODE: 'Site Code',
      SITE_NAME: 'Site Name',
      SITE_CLASS: 'Site Class',
      SITE_GROUP: 'Site Group',
      INTERNAL_STATUS: 'Internal Status',
      SUPPLIER_STATUS: 'Supplier Status',
      HISTORY: 'History',
      REJECTED_BY: 'Rejected By',
      REJECTED_ON: 'Rejected on',
      REJECTED_REASON: 'Rejected Reason',
      APPROVE: 'Approve',
      REJECT: 'Reject',
      STATUS: 'Status',
      ADD_SUPPORT: 'Add Support',
      VENDOR_IS_REQUIRED: 'Vendor is required',
      REMARK_IS_REQUIRED: 'Remark is required',
      DIVISION_IS_REQUIRED: 'Division is required',
      SECTION_IS_REQUIRED: 'Section is required',
      FROM_DATE_IS_REQUIRED: 'From Date is required',
      TO_DATE_IS_REQUIRED: 'To Date is required',
      DUE_DATE_IS_REQUIRED: 'Due Date is required',
      CONTRACT_TYPE_IS_REQUIRED: 'Contract Type is required',
      VAT_IS_REQUIRED: 'VAT is required',
      SUPPLIER_IS_NOT_MATCHING_WITH_THE_SELECTED_ARTICLE:
        'Supplier is not matching with the selected article',
      ARTICLE_CODE_IS_REQUIRED: 'Article Code is required',
      VARIANT_CODE_IS_REQUIRED: 'Variant Code is required',
      AMOUNT_WITHOUT_VAT_IS_REQUIRED: 'Amount without VAT is required',
      SELLING_PRICE_IS_REQUIRED: 'Selling Price is required',
      CHOOSE_SITE: 'Choose Site',
      SITE_SELECTED: 'Site Selected',
      CHOOSE_AT_LEAST_ONE_SITE: 'Choose at least one site',
      DUPLICATE_SUPPORT_FOUND: 'Duplicate Support Type Found',
      DUPLICATE_VARIANTS_FOUND: 'Duplicate Variants Found',
      CREATE_SELL_OUT_SUPPORT: 'Create Sell Out Support',
      UPDATE_SELL_OUT_SUPPORT: 'Update Sell Out Support',
      CREATE_SELL_IN_SUPPORT: 'Create Sell In Support',
      UPDATE_SELL_IN_SUPPORT: 'Update Sell In Support',
      CREATE_OTHER_REVENUE_SUPPORT: 'Create Other Revenue Support',
      UPDATE_OTHER_REVENUE_SUPPORT: 'Update Other Revenue Support',
      GROUP: 'Group',
      CHOOSE_VENDOR_DIVISION_AND_SECTION: 'Choose vendor, division and section',
      FILL_ALL_HEADER_FIELDS: 'Fill all header fields',
      PLEASE_FILL_ALL_THE_REQUIRED_FIELDS:
        'Please fill all the required fields',
      REASON_FOR_REJECTION: 'Reason for Rejection',
      RATIO_IS_REQUIRED: 'Ratio is required',
      TOTAL_RATIO: 'Total Ratio',
      RESET_VALUE: 'Reset Value',
      TOTAL_SITE: 'Total Site',
      TOTAL_RATIO_MUST_BE_100: 'Total Ratio must be 100',
      SUPPORT_TYPE: 'Support Type',
      SUPPORT_TYPE_IS_REQUIRED: 'Support Type is required',
      SUPPORT_CANNOT_BE_UPDATED_SINCE_IT_IS_APPROVED_BY_ENTRY_USER:
        'Support cannot be updated since it is approved by entry user',
      CHOOSE_SECTION: 'Choose Section',
      SECTION_SELECTED: 'Section Selected',
    },
    SIDEBAR: {
      DASHBOARD: 'Dashboard',
      PURCHASE_ORDER: 'Purchase Order',
      GOODS_RECEIPT_NOTE: 'Goods Receipt Note',
      INVENTORY_MANAGEMENT: 'Inventory Management',
      SELL_OUT_SUPPORT: 'Sell Out Support',
      SELL_IN_SUPPORT: 'Sell In Support',
      OTHER_REVENUE_SUPPORT: 'Other Revenue Support',
      ADMIN: 'Admin',
      CREATE_ADMIN: 'Create Admin',
      MANAGE_ADMIN: 'Manage Admin',
      INTERNAL_TEAM: 'Internal Team',
      CREATE_INTERNAL_TEAM: 'Create Internal Team',
      MANAGE_INTERNAL_TEAM: 'Manage Internal Team',
      SUPPLIER: 'Supplier',
      REPORT: 'Report',
      SUMMARIZED_REPORT: 'Summarized Report',
      CREATE_SUPPLIER: 'Create Supplier',
      MANAGE_SUPPLIER: 'Manage Supplier',
    },
    USER: {
      MY_PROFILE: 'My Profile',
      NOTIFICATION: 'Notification',
      LANGUAGE: 'Language',
      SIGN_OUT: 'Sign Out',
    },
    ADMIN: {
      USERNAME: 'Username',
      EMAIL: 'Email',
      PASSWORD: 'Password',
      PHONE: 'Phone',
      USER_LEVEL: 'User Level',
      MANAGER: 'Manager',
      COUNTRY: 'Country',
      SUPPLIER_SELECTION_TYPE: 'Supplier Selection Type',
      USER_LEVEL_CAN_ONLY_BE_BETWEEN: 'User level can only be between',
      TEAM_MEMBER_WITH_LEVEL: 'Team Member with level',
      USER_NAME_IS_REQUIRED: 'User Name is required',
      EMAIL_IS_REQUIRED: 'Email is required',
      PASSWORD_IS_REQUIRED: 'Password is required',
      PHONE_IS_REQUIRED: 'Phone is required',
      USER_LEVEL_IS_REQUIRED: 'User Level is required',
      COUNTRY_IS_REQUIRED: 'Country is required',
      MANAGER_IS_REQUIRED: 'Manager is required',
      USERNAME_CANNOT_BE_CHANGED: 'Username cannot be changed',
      EMAIL_CANNOT_BE_CHANGED: 'Email cannot be changed',
      PHONE_CANNOT_BE_CHANGED: 'Phone cannot be changed',
    },
  },
};
