// Arabic
export const locale = {
  lang: 'ar',
  data: {
    DASHBOARD: {
      OPEN_ORDERS: 'الطلبات المفتوحة',
      GRN: 'GRN',
      OOS_ITEMS: 'العناصر غير متوفرة',
      STOCK_QTY: 'كمية المخزون',
      STOCK_VALUE: 'قيمة المخزون',
      SLA: 'SLA',
      QUANTITY: 'الكمية',
      TOTAL_ORDERED_QTY: 'إجمالي الكمية المطلوبة',
      TOTAL_RECEIVED_QTY: 'إجمالي الكمية المستلمة',
      VALUE: 'القيمة',
      TOTAL_ORDERED_VALUE: 'إجمالي القيمة المطلوبة',
      TOTAL_RECEIVED_VALUE: 'إجمالي القيمة المستلمة',
      CONTRACTS: 'العقود',
      APPROVED_SELL_OUT: 'المبيعات المعتمدة',
      APPROVED_SELL_IN: 'المشتريات المعتمدة',
      APPROVED_OTHER_REVENUE: 'إيرادات أخرى معتمدة',
      PENDING_SELL_OUT: 'المبيعات المعلقة',
      PENDING_SELL_IN: 'المشتريات المعلقة',
      PENDING_OTHER_REVENUE: 'إيرادات أخرى معلقة',
      MONTHLY_ORDER_FLOW: 'تدفق الطلبات الشهري',
      MONTHLY_GRN_FLOW: 'تدفق GRN الشهري',
    },
    COMMON: {
      VENDOR: 'المورد',
      DIVISION: 'القسم',
      DEPARTMENT: 'الإدارة',
      SECTION: 'القسم',
      BRAND: 'العلامة التجارية',
      ORDER_DATE: 'تاريخ الطلب',
      DELIVERY_DEADLINE: 'الموعد النهائي للتسليم',
      SITE: 'موقع',
      DOWNLOAD: 'تحميل',
      DOWNLOADING: 'جارى التحميل',
      SEARCH: 'بحث',
      CLEAR: 'مسح',
      MORE: 'المزيد',
      LOADING_DATA: '...جاري تحميل البيانات',
      NO_DATA_FOUND: 'لم يتم العثور على بيانات',
      SHOWING: 'عرض',
      TO: 'إلى',
      OF: 'من',
      ENTRIES: 'المدخلات',
      DATE: 'تاريخ',
      FROM_DATE: 'من تاريخ',
      TO_DATE: 'إلى تاريخ',
      BACK: 'رجوع',
      NEXT: 'التالي',
      CANCEL: 'إلغاء',
      SAVE: 'حفظ',
      PROMOTION_PERIOD: 'فترة الترقية',
      HIDE: 'إخفاء',
      SHOW: 'إظهار',
      AMOUNT: 'المبلغ',
      WITHOUT_VAT: 'بدون ضريبة القيمة المضافة',
      WITH_VAT: 'بضريبة القيمة المضافة',
      RATIO: 'نسبة',
      CREATE: 'إنشاء',
      UPDATE: 'تحديث',
      SV_DESCRIPTION: 'وصف SV',
      REMARKS: 'ملاحظات',
      UPLOAD_USING_EXCEL: 'تحميل باستخدام Excel',
      DOWNLOAD_EXAMPLE: 'تحميل مثال',
      NUMBER_DETAIL_LINES: 'خطوط تفاصيل الأرقام',
      NUMBER_OF_SECTION: 'عدد الأقسام',
      NUMBER_OF_SITES: 'عدد المواقع',
    },
    PURCHASE_ORDER: {
      PURCHASE_ORDER_FILTER: 'فلتر طلب الشراء',
      SITE_NUMBER: 'رقم الموقع',
      PO_NUMBER: 'رقم طلب الشراء',
      ITEM_COUNT: 'عدد العناصر',
      AMOUNT: 'المبلغ',
      STATUS: 'الحالة',
      STATUS_IS_REQUIRED: 'الحالة مطلوبة',
    },
    GOODS_RECEIPT_NOTE: {
      GOODS_RECEIPT_NOTE_FILTER: 'تصفية مذكرة استلام البضائع',
      INVOICE_NUMBER: 'رقم الفاتورة',
      RECEPTION_DATE: 'تاريخ الاستلام',
      RECEPTION_NUMBER: 'رقم الاستلام',
      DN_NUMBER: 'رقم DN',
      VALIDATION_DATE: 'تاريخ التحقق',
    },
    INVENTORY_MANAGEMENT: {
      INVENTORY_MANAGEMENT_FILTER: 'تصفية إدارة المخزون',
      BARCODE: 'الباركود',
      STOCKS: 'المخزونات',
      ARTICLE: 'المقالة',
      SUPPLIER: 'المورد',
      STOCK_QTY: 'كمية المخزون',
      ON_ORDER_QTY: 'كمية الطلب',
      NORMAL_QTY: 'الكمية العادية',
      BLOCKED_QTY: 'الكمية المحجوزة',
      STOCK_VALUE: 'قيمة المخزون',
      TOTAL: 'الإجمالي',
      TOTAL_VALUE: 'القيمة الإجمالية',
      VIEW_TYPE: 'نوع العرض',
      ACTIVE_STOCKS: 'المخزونات النشطة',
      ITEM_STATUS: 'حالة العنصر',
    },
    SUPPORT: {
      SUPPORT_FILTER: 'تصفية الدعم',
      SELL_OUT_SUPPORT_FILTER: 'تصفية دعم المبيعات النهائية',
      SELL_IN_SUPPORT_FILTER: 'تصفية دعم المشتريات النهائية',
      OTHER_REVENUE_SUPPORT_FILTER: 'تصفية دعم الإيرادات الأخرى',
      PROMOTION_FROM: 'الترقية من',
      PROMOTION_TO: 'الترقية إلى',
      DUE_DATE: 'تاريخ الاستحقاق',
      CONTRACT_TYPE: 'نوع العقد',
      VAT: 'ضريبة القيمة المضافة',
      ADD_ITEM: 'إضافة عنصر',
      ADD_SUPPORT_TYPE: 'إضافة نوع الدعم',
      UPDATE_SUPPORT_TYPE: 'تحديث نوع الدعم',
      ARTICLE_CODE: 'رمز المقالة',
      VARIANT_CODE: 'رمز النسخة',
      AMOUNT_WITHOUT_VAT: 'المبلغ بدون ضريبة القيمة المضافة',
      AMOUNT_WITH_VAT: 'المبلغ بضريبة القيمة المضافة',
      PROMO_SELLING_PRICE_WITH_VAT: 'سعر البيع الترويجي بضريبة القيمة المضافة',
      ACTION: 'إجراء',
      SITE_CODE: 'رمز الموقع',
      SITE_NAME: 'اسم الموقع',
      SITE_CLASS: 'فئة الموقع',
      SITE_GROUP: 'مجموعة الموقع',
      INTERNAL_STATUS: 'الحالة الداخلية',
      SUPPLIER_STATUS: 'حالة المورد',
      HISTORY: 'التاريخ',
      REJECTED_BY: 'المرفوض بواسطة',
      REJECTED_ON: 'المرفوض في',
      REJECTED_REASON: 'سبب الرفض',
      APPROVE: 'الموافقة',
      REJECT: 'رفض',
      STATUS: 'الحالة',
      ADD_SUPPORT: 'إضافة دعم',
      VENDOR_IS_REQUIRED: 'المورد مطلوب',
      REMARK_IS_REQUIRED: 'الملاحظة مطلوبة',
      DIVISION_IS_REQUIRED: 'القسم مطلوب',
      SECTION_IS_REQUIRED: 'القسم مطلوب',
      FROM_DATE_IS_REQUIRED: 'تاريخ البدء مطلوب',
      TO_DATE_IS_REQUIRED: 'تاريخ الانتهاء مطلوب',
      DUE_DATE_IS_REQUIRED: 'تاريخ الاستحقاق مطلوب',
      CONTRACT_TYPE_IS_REQUIRED: 'نوع العقد مطلوب',
      VAT_IS_REQUIRED: 'ضريبة القيمة المضافة مطلوبة',
      SUPPLIER_IS_NOT_MATCHING_WITH_THE_SELECTED_ARTICLE:
        'المورد غير متطابق مع المقالة المحددة',
      ARTICLE_CODE_IS_REQUIRED: 'كود المقالة مطلوب',
      VARIANT_CODE_IS_REQUIRED: 'كود البديل مطلوب',
      AMOUNT_WITHOUT_VAT_IS_REQUIRED: 'المبلغ بدون ضريبة القيمة المضافة مطلوب',
      SELLING_PRICE_IS_REQUIRED: 'سعر البيع مطلوب',
      CHOOSE_SITE: 'اختر موقع',
      SITE_SELECTED: 'تم اختيار الموقع',
      CHOOSE_AT_LEAST_ONE_SITE: 'اختر موقع واحد على الأقل',
      DUPLICATE_SUPPORT_FOUND: 'تم العثور على نوع دعم مكرر',
      DUPLICATE_VARIANTS_FOUND: 'تم العثور على متغيرات مكررة',
      CREATE_SELL_OUT_SUPPORT: 'إنشاء دعم المبيعات النهائية',
      UPDATE_SELL_OUT_SUPPORT: 'تحديث دعم المبيعات النهائية',
      CREATE_SELL_IN_SUPPORT: 'إنشاء دعم المشتريات النهائية',
      UPDATE_SELL_IN_SUPPORT: 'تحديث دعم المشتريات النهائية',
      CREATE_OTHER_REVENUE_SUPPORT: 'إنشاء دعم الإيرادات الأخرى',
      UPDATE_OTHER_REVENUE_SUPPORT: 'تحديث دعم الإيرادات الأخرى',
      GROUP: 'مجموعة',
      CHOOSE_VENDOR_DIVISION_AND_SECTION: 'اختر المورد، القسم والجزء',
      FILL_ALL_HEADER_FIELDS: 'املأ جميع حقول الرأس',
      PLEASE_FILL_ALL_THE_REQUIRED_FIELDS: 'يرجى ملء جميع الحقول المطلوبة',
      REASON_FOR_REJECTION: 'سبب الرفض',
      RATIO_IS_REQUIRED: 'النسبة مطلوبة',
      TOTAL_RATIO: 'النسبة الإجمالية',
      RESET_VALUE: 'إعادة تعيين القيمة',
      TOTAL_SITE: 'إجمالي الموقع',
      TOTAL_RATIO_MUST_BE_100: 'يجب أن تكون النسبة الإجمالية 100',
      SUPPORT_TYPE: 'نوع الدعم',
      SUPPORT_TYPE_IS_REQUIRED: 'نوع الدعم مطلوب',
      SUPPORT_CANNOT_BE_UPDATED_SINCE_IT_IS_APPROVED_BY_ENTRY_USER:
        'لا يمكن تحديث الدعم لأنه تمت الموافقة عليه من قبل مستخدم الإدخال',
      CHOOSE_SECTION: 'اختر القسم',
      SECTION_SELECTED: 'تم اختيار القسم',
    },
    SIDEBAR: {
      DASHBOARD: 'لوحة التحكم',
      PURCHASE_ORDER: 'طلب الشراء',
      GOODS_RECEIPT_NOTE: 'مذكرة استلام البضائع',
      INVENTORY_MANAGEMENT: 'إدارة المخزون',
      SELL_OUT_SUPPORT: 'دعم المبيعات النهائية',
      SELL_IN_SUPPORT: 'دعم المشتريات النهائية',
      OTHER_REVENUE_SUPPORT: 'دعم الإيرادات الأخرى',
      ADMIN: 'المشرف',
      CREATE_ADMIN: 'إنشاء مشرف',
      MANAGE_ADMIN: 'إدارة المشرف',
      INTERNAL_TEAM: 'الفريق الداخلي',
      CREATE_INTERNAL_TEAM: 'إنشاء مشرف',
      MANAGE_INTERNAL_TEAM: 'إدارة المشرف',
      SUPPLIER: 'المورد',
      REPORT: 'تقرير',
      SUMMARIZED_REPORT: 'تقرير مختصر',
      CREATE_SUPPLIER: 'إنشاء مشرف',
      MANAGE_SUPPLIER: 'إدارة المشرف',
    },
    USER: {
      MY_PROFILE: 'ملفي الشخصي',
      NOTIFICATION: 'إشعار',
      LANGUAGE: 'اللغة',
      SIGN_OUT: 'تسجيل الخروج',
    },
    ADMIN: {
      USERNAME: 'اسم المستخدم',
      EMAIL: 'البريد الإلكتروني',
      PASSWORD: 'كلمة المرور',
      PHONE: 'الهاتف',
      USER_LEVEL: 'مستوى المستخدم',
      MANAGER: 'المدير',
      COUNTRY: 'البلد',
      SUPPLIER_SELECTION_TYPE: 'نوع اختيار المورد',
      USER_LEVEL_CAN_ONLY_BE_BETWEEN: 'يمكن أن يكون مستوى المستخدم فقط بين',
      TEAM_MEMBER_WITH_LEVEL: 'عضو في الفريق بمستوى',
      USER_NAME_IS_REQUIRED: 'اسم المستخدم مطلوب',
      EMAIL_IS_REQUIRED: 'البريد الإلكتروني مطلوب',
      PASSWORD_IS_REQUIRED: 'كلمة المرور مطلوبة',
      PHONE_IS_REQUIRED: 'الهاتف مطلوب',
      USER_LEVEL_IS_REQUIRED: 'مستوى المستخدم مطلوب',
      COUNTRY_IS_REQUIRED: 'البلد مطلوب',
      MANAGER_IS_REQUIRED: 'المدير مطلوب',
      USERNAME_CANNOT_BE_CHANGED: 'لا يمكن تغيير اسم المستخدم',
      EMAIL_CANNOT_BE_CHANGED: 'لا يمكن تغيير البريد الإلكتروني',
      PHONE_CANNOT_BE_CHANGED: 'لا يمكن تغيير الهاتف',
    },
  },
};
