import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { locale as arLang } from '../app/modules/i18n/vocabs/ar';
import { MessagingService } from './core/services/firebase-message/firebase-message.service';
import { TranslationService } from './modules/i18n';
import { locale as enLang } from './modules/i18n/vocabs/en';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private translationService: TranslationService,
    private messagingService: MessagingService
  ) {
    this.translationService.loadTranslations(enLang, arLang);

    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.currentMessage.subscribe((message) => {});
  }
}
